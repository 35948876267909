import * as React from 'react';
import Layout from '../components/layout';
import { StaticImage } from "gatsby-plugin-image";

import Helmet from "react-helmet";

import art from "../images/artistic.png"
import anal from "../images/analytic.png"

export default function freelance() {
    return(
        <Layout>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Freelance: Need maps?</title>
              <meta name="description" content="Why you should hire an independent cartographer"/>
              <meta name="image" content="https://awmcphee.ca/regina.jpg" />
              <meta property="og:image" content="https://awmcphee.ca/regina.jpg"/>
              <meta name="twitter:card" content="summary"/>
              <meta name="twitter:site" content="@ksituan"/>
              <meta name="twitter:title" content="Freelance: Need maps?"/>
              <meta name="twitter:description" content="Why you should hire an independent cartographer"/>
              <meta name="twitter:image" content="https://awmcphee.ca/regina.jpg"/>
            </Helmet>
            <h1>Need maps?</h1>
            <div className="twoColumns" id="freelanceBox">
              <div className="freelanceReason">
              <img src={anal} height="180" alt="A pronghorn sitting at a laptop" />
              <h3>I'm analytic</h3>
            <p>Need a GIS person?
              I have thousands of hours of open-source experience.
              No project is too small: I specialize in using free software to help your organization avoid big licensing fees and long-term commitments.</p>
              </div>
              <div className="freelanceReason">
                <img src={art} height="200" alt="A pronghorn drawing at a drafting table" />
              <h3>I'm artistic</h3>
            <p>I am a true cartographer in the traditional sense, holding multiple industry awards for quality in print.
              My work is a unique intersection of data visualization, graphic design, and communications.
              Let's get together and tell your story.</p>
              </div>
            </div>

            <h2>Industry awards</h2>

<div className="resumeGallery">
  <div className="resumeItem">
    <div>★</div>
    <div className="year">2019</div>
    <div className="title">Best in Design (Student)</div>
    <div className="institution">North American Cartographic Information Society</div>
  </div>

  <div className="resumeItem">
  <div>★</div>
    <div className="year">2020</div>
    <div className="title">Best Reference Map</div>
    <div className="institution">Cartography and Geographic Information Society</div>
  </div>

  <div className="resumeItem">
  <div>★</div>
    <div className="year">2020-2021</div>
    <div className="title">Stanfords Award (Highly Commended)</div>
    <div className="institution">British Cartographic Society</div>
  </div>

  <div className="resumeItem">
  <div>★</div>
    <div className="year">2022</div>
    <div className="title">Atlas of Design</div>
    <div className="institution">North American Cartographic Information Society</div>
  </div>
  </div>

            <h2>Résumé</h2>

            <div className="resumeGallery">
              <div className="resumeItem">
                <div className="year">2020</div>
                <div className="title">BSc. Geophysics (Spec.)</div>
                <div className="institution">University of Alberta</div>
                <div className="flavour">Discovered the world of map libraries and the basics of scientific image processing.</div>
              </div>
              <div className="resumeItem">
                <div className="year">2021-date</div>
                <div className="title">Proprietor</div>
                <div className="institution">Prairie Heart Maps</div>
                <div className="flavour">Southern Saskatchewan's most distinguished solo map publishing business!</div>
              </div>
              <div className="resumeItem">
                <div className="year">2021</div>
                <div className="title">Publication</div>
                <div className="institution">McPhee's Alberta</div>
                <div className="flavour">My first reference map. Won four industry awards in three countries.</div>
              </div>
              <div className="resumeItem">
                <div className="year">2021</div>
                <div className="title">Federal candidate</div>
                <div className="institution">Cypress Hills—Grasslands NDP</div>
                <div className="flavour">Learned how to accept second place graciously.</div>
              </div>
              <div className="resumeItem">
                <div className="year">2022-date</div>
                <div className="title">Webmaster</div>
                <div className="institution">Polling Canada</div>
                <div className="flavour">All the graphics and web development for Canada's largest polling aggregator.</div>
              </div>
              <div className="resumeItem">
                <div className="year">2022-2023</div>
                <div className="title">Editorial cartographer</div>
                <div className="institution">iPolitics / QP Briefing</div>
                <div className="flavour">Newsroom maps, graphics, and web interactives done fast.</div>
              </div>
              <div className="resumeItem">
                <div className="year">2022</div>
                <div className="title">Publication</div>
                <div className="institution">McPhee's Saskatchewan</div>
                <div className="flavour">An instant bestseller. Hangs in 250 different towns and now in its second edition.</div>
              </div>
              <div className="resumeItem">
                <div className="year">2023-date</div>
                <div className="title">Editorial cartographer</div>
                <div className="institution">338 Canada</div>
                <div className="flavour">Maps and web interactives for Canada's premier election forecaster.</div>
              </div>
            </div>

            <h2>Satisfied clients</h2>
            <div className="freelanceProject">
              <StaticImage
                    width="600"
                    src="../images/village_guide.png"
                    alt="A tourist orientation map of Val Marie and Grasslands National Park."
                  />
              <div>
                <h3>Val Marie visitor map</h3>
                <p><strong>Key skills:</strong> tourism development, stakeholder relations</p>
                <p>The Village of Val Marie and the administration of Grasslands National Park wanted a new visitor map that would promote both destinations.
                  Our remote corner of the province has a lot of bad roads and spotty cell service, so basic safety was a priority.
                  Many revisions were needed to showcase the best of the region while keeping tourists out of trouble.
                </p>
                <p>The map ended up being so popular with the client that it was also made into outdoor signs around the village.</p>
              </div>
            </div>

            <div className="freelanceProject">
              <StaticImage
                    width="600"
                    src="../images/minneapolis.png"
                    alt="A conference map of downtown Minneapolis."
                  />
              <div>
                <h3>NACIS program map</h3>
                <p><strong>Key skills:</strong> visual identity, visitor orientation</p>
                <p>Designing conference materials, sure. But for North America's largest society of cartographers? I had to be on top of my game!</p>
                <p>This map was created to be highly practical while also matching the colour scheme and font faces of the wider conference.</p>
                <p>As you might imagine, NACIS has no shortage of available talent when it comes to the organization's own map projects, so I was extremely honoured to be chosen for the 2022 program.</p>
              </div>
            </div>

            <div className="freelanceProject">
              <StaticImage
                    width="600"
                    src="../images/bilingualism.png"
                    alt="A thematic map of bilingualism in Canada."
                  />
              <div>
                <h3>iPolitics datajournalism</h3>
                <p><strong>Key skills:</strong> communications, very fast turnaround</p>
                <p>This is one of my favourite examples of maps created to drum up interest in iPolitics' subscriber-only newsroom content.</p>
                <p>The goal was to be the first to publish whenever a new census or election dataset was released - often unexpectedly.
                  Very different from my usual slow, methodical projects!</p>
                <p>My clients will tell you that I took to the time pressure fairly well, and I still hold a number of political contracts today.</p>
              </div>
            </div>

            <h2>What to expect</h2>
            <p>I estimate based on my productive time.
              My hourly rate is $80, and my daily rate is $600.
              Please note my productive time is extremely productive; all of these example projects were made in less than a week.</p>
            <p>I like to give a single quote at the beginning of a project, and I will do my best to inform you if the work is moving in a direction that's wildly off estimate.
              A planning meeting is always free!</p>
            <p>After receiving an estimate, expect to pay half up front, and work will begin summarily.
              I will produce invoices as needed to keep your accountant satisfied.</p>
        </Layout>
    );
}